import { prefix } from './config';

export default {
  FILE_UPLOAD: `${prefix}/hydra-open-thirdpart-service/hydra-open-third-party/api/v1/file/open/upload`,
  FILE_UPLOAD_AJAX: '/hydra-open-thirdpart-service/hydra-open-third-party/api/v1/file/open/upload`',
  QRCODE: `${prefix}/hydra-base-data/api/v1/common/qrCode`,
  CONFIF_TYPE: '/hydra-base-data/api/v1/config/type',

  message: {
    UNREAD: '/hydra-user-msg/api/v1/msg/mine/unread/list',
    LIST: '/hydra-user-msg/api/v1/msg/mine/list',
    INFO: '/hydra-user-msg/api/v1/msg/info',
    COUNT: '/hydra-user-msg/api/v1/msg/mine/unread/count',
  },
  //统计用户菜单访问
  statistics: {
    SAVE: '/productmanage/productionManageFunctionUse/save'
  },
  loadJgwJssdkinfo: '/marketing/common/jgwJssdkinfo',
  //获取行政区划信息
  region: {
    //获取所有省份信息
    GET_PRIVINCE: '/hydra-base-data/api/v1/address/first',
    // 根据父级行政编码 获取所有子级行政区
    GET_CHILDREN: '/hydra-base-data/api/v1/address/child',
    // 根据行政区编码 获取行政区信息
    GET_DETAIL: '/hydra-base-data/api/v1/address',
    // 获取所有父级行政区
    GET_ALL_PARENTS: '/hydra-base-data/api/v1/address/all-regions'
  },
  user: {
    // 获取用户员工信息
    GET_USER_EMPLOEE: '/hydra-user/api/v1/employee/cur/user/employee',
    //
    GET_USER_EMPLOEE_DETAIL: '/hydra-user/api/v1/employee/cur/user/employee-obj',
  },
  token: {
   UPDATE: '/hydra-user/api/v1/filter/auth/access'
  },
  sms: {
    // 发送手机验证码
    SEND_CODE: '/hydra-user/api/v1/sms/send/phone/code',
    // 校验手机验证码
    CHECK_CODE: '/hydra-user/api/v1/sms/check/phone-code'
  },
  file: {
    PRE_CREATE: '/hydra-base-data/api/v1/file/pre-create',
    CREATE: '/hydra-base-data/api/v1/file/create'
  }
};
