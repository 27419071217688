/*
 * @Author: duyaoyao
 * @Date: 2021-01-28 17:21:01
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-12-13 16:54:28
 */
import dynamic from "dva/dynamic";

const routeData = [
  {
    path: "/base/basicInfo",
    component: dynamic({
      component: () => import("./views/BasicInfo")
    })
  },
  {
    path: "/base/userInfo",
    component: dynamic({
      component: () => import("./views/UserInfo")
    })
  }
];
export default routeData;
