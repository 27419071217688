/*
 * @Author: duyaoyao
 * @Date: 2021-01-26 15:16:41
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-04-14 15:16:35
 */
export default {
  //登陆
  LOGIN: "/oauth/form-login",
  // 获取组织详情
  APPLIST: "/oauth/user/app/select",
  PASSWORD: "/oauth/account/password",
  // 校验绑定浙政钉
  CHECK:'/open/api/v1/public/bind/zzd/check',
  // 绑定浙政钉
  BINDZZD:'/open/api/v1/public/bind/zzd'
};
