export default {
  // 获取用户组织
  GET_USER_ORG_LIST: '/hydra-user/api/v1/org/user/id',
  // 获取用户平台
  GET_USER_MANAGER_LIST: '/sys/manager-list',
  // 获取邀请信息
  GET_INVITE_DETAIL: '/org/sys/invite/one',   //   作废
  // 邀请---同意授权
  INVITE_CONFIRM: '/org/sys/invite/confirm',    //   作废
  // 模糊搜索组织
  SEARCH_ORG: '/hydra-user/api/v1/org/active',
  // 设置当前选择的组织
  SET_VIEW_ORG: '/hydra-user/api/v1/user/set/org',
  // 判断邀请链接是否失效
  IS_INVITE_LINK_ENABLE: '/org/sys/invite/check/effective',     //   作废
  // 根据邀请id获取邀请url的手机号
  GET_INVITE_MOBILE: '/org/sys/invite/mobile',     //   作废
  // 根据组织id获取单条组织信息
  GET_ORG_DETAIL: '/hydra-user/api/v1/org',
  // 申诉工单
  GET_LIST_APPEAL: '/hydra-user/api/v1/user/org/appeal/list',
};
