export default {
  //获取组织下的系统
  GET_ORG_SYSTEM_LIST: '/hydra-user/api/v1/sys/org/auth/list',
  //根据url获取系统信息
  GET_SYSTEM_DETAIL: '/hydra-user/api/v1/sys/sys/url',
  // 设置当前选择的系统
  SET_VIEW_SYS: '/hydra-user/api/v1/user/set/sys',
  //校验登入用户是否为系统管理员
  CHECK_USER_SYSTEM_ADMIN: '/hydra-user/api/v1/userAdmin/checkadmit',
  //根据系统id获取组织列表
  GET_ORG_BY_SYS: '/hydra-user/api/v1/org/sys/filter-user',
  //获取用户已加入的组织列表
  GET_USER_ORG: '/hydra-user/api/v1/org/user/id',
  //按组织id判断是否有着陆页
  CHECK_ORG_HAS_LANDINGPAGE: '/hydra-base-data/api/v1/user/landing/page/dujge/page',
  //获取系统过期时间
  GET_SYS_VALID_DATE: '/hydra-user/api/v1/sys/org/time/validity'
}
