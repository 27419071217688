/*
 * @Author: duyaoyao
 * @Date: 2021-04-25 09:56:45
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-11-11 15:11:00
 */
import * as storage from '../utils/storage';
import { ajaxSync } from '../utils/ajax';
import _ from 'lodash';
import userApi from 'userCenter/common/api';
import api from 'framework/common/api';

let userDetailSave = null;
export const loadDetail = async (getEmployee = true) => {
  const userDetail = userDetailSave; // storage.get('userDetail');
  let result = null;
  if (userDetail) {
    result = userDetail;
  } else {
    const baseInfo = await ajaxSync.get(userApi.user.GET_USER_INFO);
    const detailInfo = await ajaxSync.get(userApi.user.GET_USER_DETAIL_INFO);
    const orgInfo = await ajaxSync.get(userApi.user.ORG_UNION);
    if (baseInfo.err || detailInfo.err) {
      result = null;
    } else {
      const baseInfoResult = _.get(baseInfo, 'res.results', {});
      const detailInfoResult = _.get(detailInfo, 'res.results', {});
      const orgInfoResult = _.get(orgInfo, 'res.results', {});
      result = { ...detailInfoResult, ...baseInfoResult, ...orgInfoResult };
      orgInfoResult && storage.set('orgUnionInfo', result);
      result && storage.set('baseInfo', result);
    }
  }
  if (result && getEmployee) {
    result.employeeId = await getLoginUserEmployeeId();
  }
  return result;
};
export const loadUserDetail = loadDetail;
//获取当前登陆用户的员工ID
export const getLoginUserEmployeeId = async () => {
  const { err, res } = await ajaxSync.get(api.user.GET_USER_EMPLOEE);
  if (!err && res) {
    return _.get(res,'results.value');
  }
};
export const clear = () => {
  userDetailSave = null;
};
export const saveDetail = (detail) => {
  //storage.set('userDetail', detail);
  saveOrgDetail(detail);
  userDetailSave = detail;
};

function saveOrgDetail(detail) {
  let [orgID, orgDetail] = [null, null];
  if (detail) {
    const { organizationCache } = detail
    orgDetail = organizationCache;
    orgID = orgDetail ? orgDetail.organizationId : null;
  }
  storage.set('orgID', orgID);
  storage.set('orgDetail', orgDetail)
}

export const logout = async () => {
  await ajaxSync.get(userApi.user.LOGOUT);
};

export const getThemeConfig = async () => {
  const { err, res } = await ajaxSync.get('/hydra-user/api/v1/user/theme');
  if (!err && res && res.results) {
    return JSON.parse(res.results);
  }
  return {};
};
export const setThemeConfig = async (config) => {
  let success = true;
  const { err, res } = await ajaxSync.post('/hydra-user/api/v1/user/theme', { themeComment: JSON.stringify(config) });
  if (err) {
    success = false;
  }
  return success;
};
