import React from 'react';
import {connect} from 'dva';
import {Modal} from 'antd';
const {info} = Modal;
//
let visible = false;
let isLogout = false;
export const setLogoutState = () => {
  isLogout = true;
};
//
@connect(null, null, null, {withRef: true})
export default class extends React.Component {
  state = {modal: null};
  show = () => {
    if (isLogout) {
      return;
    }
    if (!visible) {
      visible = true;
      const {dispatch} = this.props;
      const infoModal = info({
        content: '页面已过期，请重新登录。',
        okText: '前往登录',
        onOk: () => {
          infoModal.destroy();
          this.setState({modal: null});
          dispatch({type: 'user/logout'});
          setTimeout(() => {
            visible = false;
          }, 2000);
        }
      });
      this.setState({modal: infoModal});
    }
  };

  render() {
    return null
  }
}
