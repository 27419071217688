/*
 * @Author: duyaoyao
 * @Date: 2021-02-02 17:52:55
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-02-20 13:51:58
 */
import createApp from 'framework/createApp';
import {setConfig} from 'framework/siteConfig';
import routeMainComponent from './routes';
import routerConfig from './routes/routerConfig';
import api from './config/api';
import menuData from './config/menu';

setConfig('routeMainComponent', routeMainComponent);
setConfig('routerConfig', routerConfig);
setConfig('menuData', menuData);
setConfig('apiUrl', api);
setConfig('logo', '婺城区数字乡村');
setConfig('logout',()=>{
  console.log('logouts');
})
const app = createApp();
export default app._store;
