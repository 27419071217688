/*
 * @Author: duyaoyao
 * @Date: 2020-12-29 13:49:09
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-10-19 14:10:29
 */
import MenuItem from "framework/common/MenuItem";
import config from "framework/common/config";

const accountMenu = new MenuItem("系统快捷入口");
accountMenu.set({ path: "/base/basicInfo" });

const passwordMenu = new MenuItem("修改密码");
passwordMenu.set({ path: "/base/userInfo" });

const adminMenu = new MenuItem("管理后台入口");
adminMenu.set({ type: 1, url: config.wuchengAdmin });

export default [accountMenu, passwordMenu, adminMenu];
