import React from 'react';
import {routerRedux, Route, Switch} from 'dva/router';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import {getRouterData} from './common/router';

const {ConnectedRouter} = routerRedux;

function RouterConfig({history, app}) {
  const routerData = getRouterData(app);
  const routerDataArray = Object.keys(routerData)
    .map(path => ({path, component: routerData[path].component}))
    .sort((left) => left.path === '/' ? 1 : -1);
  return (
    <ConfigProvider locale={zhCN}>
      <ConnectedRouter history={history}>
        <Switch>
          {routerDataArray.map((router, index) => <Route key={index} path={router.path} component={router.component}/>)}
        </Switch>
      </ConnectedRouter>
    </ConfigProvider>
  );
}

export default RouterConfig;
