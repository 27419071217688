/*
 * @Author: duyaoyao
 * @Date: 2021-04-25 09:56:48
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-12-13 16:54:18
 */
export default ({ app, dynamicWrapper }) => {
  return {
    "/": {
      component: dynamicWrapper(app, [], () =>
        import(/*webpackChunkName: "modules/layouts/IotLayout" */ "../layouts/IotLayout")
      )
    },
    "/user": {
      component: dynamicWrapper(app, [], () =>
        import(/*webpackChunkName: "modules/layouts/UserLayout" */ "../layouts/UserLayout")
      )
    },
    "/error": {
      component: dynamicWrapper(app, [], () =>
        import(/*webpackChunkName: "modules/layouts/UserLayout" */ "../routes/views/Error/index")
      )
    }
  };
};
