export default {
  namespace: 'crumb',
  state: {
    list: []
  },
  effects: {
    // 设置面包屑
    *crumbChange({payload}, {put}){
      const {list} = payload;
      yield put({type: 'setCrumb', payload: {list}});
    }
  },
  reducers: {
    setCrumb(state, {payload}){
      const {list} = payload;
      return {...state, list};
    }
  },
  subscriptions: {},
};
