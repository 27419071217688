/*
 * @Author: duyaoyao
 * @Date: 2021-06-25 15:02:23
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-10-19 14:05:38
 */
const base = `http://wucheng-szxc.kf315.net/`;

export default {
  //
  domain: ".kf315.net",
  // 企业中心地址
  orgSite: base + "org/",
  // 用户中心地址
  userCenterSite: base + "userCenter/",
  // 用户中心登录页
  userCenterLogin: base + "userCenter/#/user/login",
  // 系统中心地址
  systemSite: base + "system",
  // 系统中心  企业着落页地址
  systemLandingPageSite: base + `system//#/platform/landingPage/exhibit`,
  // 平台中心地址
  platformSite: base + "platform/",
  // 新用户注册
  register: base + "userCenter/#/user/register",
  // 单点登录iframe的src
  ssoSrc: base + "userCenter/#/frame/user/login",
  // 图片上传返回值
  // fileDomain: "https://filetest.jgwcjm.com",
  fileDomain:"/apiInterface/interface/hydra-open-third-party/api/v1/hydra-file/previewFile",
  // 视频文件域名
  videoDomain: "https://cjm3v.kf315.net",
  // 溯源预览，h5模板预览
  previewWarp: "http://trace.h5.kf315.net",

  mapjs: "http://gis.kf315.net",

  nurturePreview: "http://nurtureh5.kf315.net",
  //贫困户预览
  previewPorverty: "http://poverty.kf315.net",
  // 溯源扫码
  traceH5: "http://trace.h5.kf315.net/#/traceH5",
  // 码营销H5
  integralMarketingH5Site: "https://marketh5.kf315.net",
  //会员御花园
  integralShop: "https://shoph5.kf315.net",
  //政府对接
  govConnectSite: "http://gov.connect.kf315.net",
  //iot平台
  iotSite: base + "iot/",
  //一体机
  aioSite: "http://aio.h5.kf315.net",
  // 保险理赔H5
  insuranceDomain: "http://insurance.h5.kf315.net/#/trace",
  //数据屏地址
  monitorScreenConfigPage: "http://datav3.kf315.net",
  //润达数据屏预览地址
  monitorScreenConfigPageRunDa: "http://freedom.h5.kf315.net/datascreen/runda/",
  monitorScreenConfigPageGugeng: "http://freedom.h5.kf315.net/dataScreen/gugeng/",
  //微页配置工具地址
  microPageSite: "http://designw.kf315.net",
  //H5工具地址
  h5ToolsSite: {
    home: "https://h5toolsv3.kf315.net/h5Tools/home/index.html", //访问地址
    preview: "https://h5toolsv3.kf315.net/h5Tools/preview/index.html", //预览地址
    settings: "https://h5toolsv3.kf315.net/h5Tools/settings/index.html" //配置地址
  },
  //防伪模板三链接
  antiHref3: "http://antibackend.h5.kf315.net/u/r/h3",
  // 农安信用跳转链接
  creditHref: "http://192.168.5.35:8001/h5/credit.html",
  // 企业工具跳转链接
  mobileAppHref: "https://test-mobile-website-h5.jgwcjm.com",
  //数字乡村一户一码
  digitalVillageHref: base + "digitalVillage/",
  //是否为超级码的码连接
  isCjmCode: "//code.kf315.net",
  // 防伪数据屏
  antiFakeDataScreen: "http://freedom.h5.kf315.net/datascreen/antiFake",
  // 基地监管GIS地图
  baseGISDataScreen: "http://freedom.h5.kf315.net/datascreen/kaihua",

  // 防伪
  antifakePlatform: "https://antifake.h5.kf315.net/",
  // b2b商城
  B2BShop: "https://b2b.kf315.net/",
  //扶贫预览
  previewPorvertyH5: "http://povertyh5.kf315.net",
  // 码链接前缀
  codeLinkPrefix: "http://code.kf315.net/u/14/",
  //沛县二维码
  peixianQrCode: "https://peixian-h5.p.kf315.net/",
  //岚翠会员商城域名
  lancuiShopH5Domain: "http://lcshop-h5.p.kf315.net",
  // 婺城二维码
  wuchengQrCode: base,
  wuchengAdmin: base + "admin/",
  wuchengUser: base + "user/"
};
