import React, {createElement} from 'react';
import Loadable from 'react-loadable';
import pathToRegexp from 'path-to-regexp';
import {Spin} from 'antd';
import {getConfig} from 'framework/siteConfig';
import {getMenuData} from './menu';

function modelNotExisted(app, model) {
  return !app._models.some(({namespace}) => {
    return namespace === model.substring(model.lastIndexOf('/') + 1);
  });
}
let routerDataCache;
function dynamicWrapper(app, models, component) {
  models.forEach(model => {
    if (modelNotExisted(app, model)) {
      app.model(require(`../models/${model}`).default);
    }
  });
  if (component.toString().indexOf('.then(') < 0) {
    return props => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return createElement(component().default, {...props, routerData: routerDataCache});
    };
  }
  return Loadable({
    loader: () => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return component().then(raw => {
        const Component = raw.default || raw;
        return props => createElement(Component, {...props, routerData: routerDataCache});
      });
    },
    loading: () => (<Spin size="large" className="global-spin"/>),
  });
}


function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach(item => {
    if (item && item.children) {
      keys[item.path] = {...item};
      keys = {...keys, ...getFlatMenuData(item.children)};
    } else if(item) {
      keys[item.path] = {...item};
    }
  });
  return keys;
}

export function getRouterData(app) {
  const getSiteRouterConfig = getConfig('routerConfig');
  const siteRouterConfig = getSiteRouterConfig ? getSiteRouterConfig({app, dynamicWrapper, modelNotExisted}) : {};
  const routerConfig = {
    '/': {
      component: dynamicWrapper(app, ['apiUrl', 'menu', 'crumb', 'user'], () => import('../layouts/BasicLayout')),
    },
    '/user': {
      component: dynamicWrapper(app, [], () => import('../layouts/UserLayout')),
    },
    '/transfer': {
      component: dynamicWrapper(app, [], () => import('../layouts/TransferLayout')),
    }, ...siteRouterConfig,
  };
  const menuData = getFlatMenuData(getMenuData());
  const routerData = {};
  Object.keys(routerConfig).forEach(path => {
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find(key => pathRegexp.test(`${key}`));
    let menuItem = {};
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb,
    };
    routerData[path] = router;
  });
  return routerData;
}
