/*
 * @Author: duyaoyao
 * @Date: 2021-06-25 15:02:23
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-11-29 17:46:28
 */
const base = `//szxc.nync.wuch.gov.cn/`;

export default {
  //
  domain: ".kf315.net",
  // 企业中心地址
  orgSite: base + "org/",
  // 用户中心地址
  userCenterSite: base + "userCenter/",
  // 用户中心登录页
  userCenterLogin: base + "userCenter/#/user/login",
  // 系统中心地址
  systemSite: base + "system",
  // 系统中心  企业着落页地址
  systemLandingPageSite: base + `system//#/platform/landingPage/exhibit`,
  // 平台中心地址
  platformSite: base + "platform/",
  // 新用户注册
  register: base + "userCenter/#/user/register",
  // 单点登录iframe的src
  ssoSrc: base + "userCenter/#/frame/user/login",
  // 图片上传返回值
  // fileDomain: "https://szxc.nync.wuch.gov.cn/oss",
  fileDomain:"/apiInterface/interface/hydra-open-third-party/api/v1/hydra-file/previewFile",
  // 视频文件域名
  videoDomain: "https://cjm3v.jgwcjm.com",
  // 溯源预览，h5模板预览
  previewWarp: "http://trace.h5.kf315.net",

  mapjs: "https://gis.jgwcjm.com",

  nurturePreview: "https://nurtureh5.jgwcjm.com",
  //贫困户预览
  previewPorverty: "https://poverty.jgwcjm.com",
  // 溯源扫码
  traceH5: "https://traceh5.jgwcjm.com/#/traceH5",
  // 码营销H5
  integralMarketingH5Site: "https://marketh5.jgwcjm.com",
  //会员御花园
  integralShop: "https://shoph5.jgwcjm.com",
  //政府对接
  govConnectSite: "http://gov.connect.jgwcjm.com",
  //iot平台
  iotSite: base + "iot/",
  //一体机
  aioSite: "http://aio.h5.jgwcjm.com",
  // 保险理赔H5
  insuranceDomain: "http://insurance.h5.jgwcjm.com/#/trace",
  //数据屏地址
  monitorScreenConfigPage: "http://datav.jgwcjm.com",
  //润达数据屏预览地址
  monitorScreenConfigPageRunDa: "https://freedom.jgwcjm.com/dataScreen/runda/",
  monitorScreenConfigPageGugeng: "https://freedom.jgwcjm.com/dataScreen/gugeng/",
  //微页配置工具地址
  microPageSite: "http://designw.jgwcjm.com",
  //H5工具地址
  h5ToolsSite: {
    home: "https://h5tools.jgwcjm.com/h5Tools/home/index.html", //访问地址
    preview: "https://h5tools.jgwcjm.com/h5Tools/preview/index.html", //预览地址
    settings: "https://h5tools.jgwcjm.com/h5Tools/settings/index.html" //配置地址
  },
  //防伪模板三链接
  antiHref3: "http://antibackend.jgwcjm.com/u/r/h3",
  // 农安信用跳转链接
  creditHref: "",
  // 企业工具跳转链接
  mobileAppHref: "https://mobile-website-h5.jgwcjm.com",
  //数字乡村一户一码
  digitalVillageHref: base + "digitalVillage/",
  //是否为超级码的码连接
  isCjmCode: "//51cjm.cn",
  // 防伪数据屏
  antiFakeDataScreen: "https://freedom.jgwcjm.com/dataScreen/antiFake",
  // 基地监管GIS地图
  baseGISDataScreen: "https://freedom.jgwcjm.com/dataScreen/kaihua",

  // 防伪
  antifakePlatform: "https://antifakeh5.jgwcjm.com/",

  // b2b商城
  B2BShop: "https://b2b.jgwcjm.com/",
  //扶贫预览
  previewPorvertyH5: "https://povertyh5.jgwcjm.com/",
  // 码链接前缀
  codeLinkPrefix: "http://51cjm.cn/u/14/",
  //沛县二维码
  peixianQrCode: "https://peixian-h5.jgwcjm.com/",
  //岚翠会员商城域名
  lancuiShopH5Domain: "http://lcshop-h5.jgwcjm.com",
  // 婺城二维码
  wuchengQrCode: base,
  wuchengAdmin: base + "admin/",
  wuchengUser: base + "user/"
};
