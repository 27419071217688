/*
 * @Author: duyaoyao
 * @Date: 2020-12-29 13:49:08
 * @LastEditors: duyaoyao
 * @LastEditTime: 2020-12-29 16:16:20
 */
export default {
  //
  domain: '.znm.zjagri.cn',
  // 企业中心地址
  orgSite: 'http://org.znm.zjagri.cn',
  // 用户中心地址
  userCenterSite: 'http://login.znm.zjagri.cn',
  // 用户中心登录页
  userCenterLogin: 'http://login.znm.zjagri.cn/#/user/login',
  // 系统中心地址
  systemSite: 'http://system.znm.zjagri.cn',
  // 系统中心  企业着落页地址
  systemLandingPageSite: `http://system.znm.zjagri.cn/#/platform/landingPage/exhibit`,
  // 平台中心地址
  platformSite: 'http://platform.znm.zjagri.cn',
  // 新用户注册
  register: 'http://login.znm.zjagri.cn/#/user/register',
  // 单点登录iframe的src
  ssoSrc: 'http://login.znm.zjagri.cn/#/frame/user/login',
  // 图片上传返回值
  // fileDomain: 'http://filetest.cjm.so',
  fileDomain:"/apiInterface/interface/hydra-open-third-party/api/v1/hydra-file/previewFile",
  // 视频文件域名
  videoDomain: 'https://cjm3v.jgwcjm.com',
  // 溯源预览，h5模板预览
  previewWarp: 'http://trace-h5.znm.zjagri.cn',
  mapjs: 'http://gis.kf315.net',
  nurturePreview: 'http://nurtureh5.kf315.net',
  //贫困户预览
  previewPorverty: 'http://poverty.kf315.net',
  // 溯源扫码
  traceH5: 'http://trace-h5.znm.zjagri.cn/#/traceH5',
  // 码营销H5
  integralMarketingH5Site: 'http://market-h5.znm.zjagri.cn',
  //会员御花园
  integralShop: 'http://shop-h5.znm.zjagri.cn',
  //政府对接
  govConnectSite: 'http://gov.connect.kf315.net',
  //iot平台
  iotSite: 'http://iot.kf315.net',
  //一体机
  aioSite: 'http://aio.h5.kf315.net',
  // 保险理赔H5
  insuranceDomain: 'http://insurance.h5.kf315.net/#/trace',
  //数据屏地址
  monitorScreenConfigPage: 'http://datav3.kf315.net',
  //润达数据屏预览地址
  monitorScreenConfigPageRunDa: 'http://freedom.h5.kf315.net/datascreen/runda/',
  monitorScreenConfigPageGugeng: 'http://freedom.h5.kf315.net/dataScreen/gugeng/',
  //微页配置工具地址
  microPageSite: 'http://designw.kf315.net',
  //H5工具地址
  h5ToolsSite: {
    home: 'https://h5toolsv3.kf315.net/h5Tools/home/index.html', //访问地址
    preview: 'https://h5toolsv3.kf315.net/h5Tools/preview/index.html', //预览地址
    settings: 'https://h5toolsv3.kf315.net/h5Tools/settings/index.html' //配置地址
  },
  //防伪模板三链接
  antiHref3: 'http://antibackend.h5.kf315.net/u/r/h3',
  // 农安信用跳转链接
  creditHref: 'https://szxc.nync.wuch.gov.cn/user/static/js/main-748942c6.7f3d9ab3.chunk.js',
  // 企业工具跳转链接
  mobileAppHref: 'https://test-mobile-website-h5.jgwcjm.com',
  //数字乡村一户一码
  digitalVillageHref: 'http://digitalVillage.kf315.net',
  //是否为超级码的码连接
  isCjmCode: '//code.kf315.net',
  // 防伪数据屏
  antiFakeDataScreen: 'http://freedomh5.sysjxg.kf315.net/dataScreen/antiFake',
  // 基地监管GIS地图
  baseGISDataScreen: 'http://freedom.h5.kf315.net/datascreen/kaihua',

  // 防伪
  antifakePlatform: 'https://antifake.h5.kf315.net/',
  // b2b商城
  B2BShop: 'https://b2b.kf315.net/',
  //扶贫预览
  previewPorvertyH5: 'http://povertyh5.kf315.net'
};