import React from 'react';
import {connect} from 'dva';
import {Switch, Route, Redirect} from 'dva/router';
import routeData from './routeData';
//
@connect()
export default class extends React.Component {
  render() {
    return (
      <Switch>
        <Redirect exact from="/" to="/user/login" />
        {routeData.map((route, index) => <Route key={index} path={route.path} component={route.component} />)}
      </Switch>
    )
  }
}
